<template>
  <content-wrapper>
    <events />
  </content-wrapper>
</template>

<script>
import Events from "@/components/Events";
import ContentWrapper from "@/components/Wrappers/ContentWrapper";

export default {
  components: {
    ContentWrapper,
    Events
  }
};
</script>
